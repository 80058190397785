import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useDispatch } from "react-redux";
import { login } from "../Redux/AuthSlice";
import { useHistory } from 'react-router-dom'
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const userLogins = [
  {
    userName: "a",
    password: "a",
  },
  {
    userName: "integration@gfa-hub.com",
    password: "Int3gration",
  },
  {
    userName: "integration2@gfa-hub.com",
    password: "Int3grationTwo",
  },
  {
    userName: "integration3@gfa-hub.com",
    password: "Int3gration3",
  },
  {
    userName: "integration4@gfa-hub.com",
    password: "Int3gration4",
  },
  {
    userName: "integration5@gfa-hub.com",
    password: "Int3grationFIV3",
  },
  {
    userName: "integration6@gfa-hub.com",
    password: "Int3grationSix",
  },
  {
    userName: "integration7@gfa-hub.com",
    password: "Int3gration7",
  },
  {
    userName: "integration8@gfa-hub.com",
    password: "Int3gration8",
  },
  {
    userName: "integration9@gfa-hub.com",
    password: "Int3grationNIN3",
  },
  {
    userName: "integration10@gfa-hub.com",
    password: "Integration10",
  },
  {
    userName: "andy.speight@agendas.group",
    password: "Travelgenix@19482",
  },
  {
    userName: "vra@viafare.com",
    password: "ViaFare@58329",
  },
  {
    userName: "prashant.chothani@travelxp.tv",
    password: "TravelXP@95924",
  },
  {
    userName: "am@a2ztraveltechnology.com",
    password: "a2z@2021125638",
  },
  {
    userName: "kiran.p@akbartravelsonline.com",
    password: "akbartravelsonline@20227830244",
  },
  {
    userName: "om_online@akbargulf.com",
    password: "akbargulf@20227830244",
  },
  {
    userName: "s.sunil@claritysso.com",
    password: "claritysso@2022463728",
  },
  {
    userName: "omflights@akbargulf.com",
    password: "omflights@2022633947",
  },
  {
    userName: "saflights@akbargulf.com",
    password: "saflights@2022633947",
  },
  {
    userName: "qaflights@akbargulf.com",
    password: "qaflights@2022633947",
  },
  {
    userName: "kwflights@akbargulf.com",
    password: "kwflights@2022633947",
  },
  {
    userName: "bhflights@akbargulf.com",
    password: "bhflights@2022633947",
  },
  {
    userName: "murthy.aradhi@fastcollab.com",
    password: "fastcollab@2022877463",
  },
  {
    userName: "keng.dtw@gtttravel.com",
    password: "gtttravel@202249257",
  },
  {
    userName: "maammer.hany@hotmail.com",
    password: "MadariGroup@2022982957",
  },
  {
    userName: "support@buyairticket.com",
    password: "buyairticket@2022983139",
  },
  {
    userName: "cosmosbd7@gmail.com",
    password: "CosmosHoliday@20221702",
  },
  {
    userName: "mozammel@skytravels24.com",
    password: "SkyTravels@20221702",
  },
  {
    userName: "prabhakar.basiri@quest2travel.com",
    password: "Quest2Travel@20221702",
  },
  {
    userName: "segun_aeroland@yahoo.com",
    password: "Aeroland@20221702",
  },
  {
    userName: "rashid@systrixit.com",
    password: "Systrix@20222305",
  },
  {
    userName: "info@holidaydragons.co.uk",
    password: "NewariLtd@20223105",
  },
  {
    userName: "hiru@heritagebd.com",
    password: "HeritageAir@20220705",
  },
  {
    userName: "ryan@faredepot.com",
    password: "faredepot@20220714",
  },
  {
    userName: "brett@mytrustedtravel.co.uk",
    password: "TravelSafeService@20220728",
  },
  {
    userName: "mahbub73@hotmail.com",
    password: "Mahbub73$20220804",
  },
  {
    userName: "jasyatra@gmail.com",
    password: "Jasyatra@1032022",
  },
  {
    userName: "siddhesh.m@multilinkworld.com",
    password: "ETrav@T3ch",
  },
  {
    userName: "abraham.t@tripbrandsgroup.com",
    password: "TAbr@h@m2301",
  },
  {
    userName: "david.joels@pingfares.com",
    password: "P!ngf@r3$2302",
  },
  {
    userName: "varun@wtfares.com",
    password: "Vs@rT3ch$2303",
  },
  {
    userName: "bala@trexedia.com",
    password: "B@l@$2303",
  },
  {
    userName: "marzeena@manashholidays.com",
    password: "M@n@$hH01idays",
  },
  {
    userName: "ceo@redseaexperience.co.uk",
    password: "R3d$eAExp23",
  },
  {
    userName: "vra@viafare.com",
    password: "V1@far3@23",
  },
  {
    userName: "manoj@regencyholidays.com",
    password: "M@n0jH0l!days",
  },
  {
    userName: "sb@wwhft.com",
    password: "Hum@yunTr@v3!s",
  },
  {
    userName: "operations@moonline.iq",
    password: "B@by!0nB00k",
  },
  {
    userName: "david@france-skiing.net",
    password: "Fr@nc3Sk!1ng",
  },
  {
    userName: "jayaprakash@infodynamic.net",
    password: "!nf0Dyn@m!c",
  },
  {
    userName: "smsajid@primetechbd.com",
    password: "Pr!m3T3ch23",
  },
  {
    userName: "mail@firsttrip.com",
    password: "F!r$tTr1p23",
  },
  {
    userName: "paul@thetravelhouse.co.uk",
    password: "Th3Tr@v3!h0use",
  },
  {
    userName: "cmytripintl@gmail.com",
    password: "CmuTr!p!nt123@",
  },
  {
    userName: "planetotahelpdesk@gmail.com",
    password: "P1@netT0ur$23",
  },
  {
    userName: "rasheed@deiratravels.ae",
    password: "D3!r@Tr@ve!23",
  },
  {
    userName: "bhaskar.rao@myholidays.com",
    password: "Cu$t0m3rD3m0!ur0",
  },
  {
    userName: "m.marketing@rodextravel.tours",
    password: "R0d3xT0ur$23",
  },
  {
    userName: "info@gandetravels.com",
    password: "Inf0@G@nd3Trave1",
  },
  {
    userName: "darren.anderson@zulutravelservices.com",
    password: "Zu!uTr@ve1S3rvic3$",
  },
  {
    userName: "info@jeresferie.dk",
    password: "J3r3$fer!eDk23",
  },
  {
    userName: "siddharth.kapoor@ebixcash.com",
    password: "23Eb!xC@sh11",
  },
  {
    userName: "ntegeka.isaac@gmail.com",
    password: "F3l!ci@C0nsu1ts",
  },
  {
    userName: "mariam@alwasattravels.com",
    password: "A1w@satTr@v3l$23",
  },
  {
    userName: "helen.cai@didatravel.com",
    password: "D!daTr@ve1s23",
  },
  {
    userName: "dhalioverseasltd@gmail.com",
    password: "64@710v3r53@5",
  },
  {
    userName: "agodagetfares@yopmail.com",
    password: "@90d@937f@635",
  },
  {
    userName: "lkullur@gmail.com",
    password: "1ku11u6",
  }
];
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(12),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const history = useHistory()
  const classes = useStyles();
  const dispatch = useDispatch();

  const [userName, setuserName] = useState("");
  const [userNameHelper, setuserNameHelper] = useState("");

  const [password, setpassword] = useState("");
  const [passwordHelper, setpasswordHelper] = useState("");
  
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const onChangeSCForm = (event) => {
    let valid;
    switch (event.target.id) {
      case "email":
        setuserName(event.target.value);
        valid = event.target.value.length !== 0;
        if (!valid) {
          setuserNameHelper("please enter user name");
        } else {
          setuserNameHelper("");
        }
        break;
      case "password":
        setpassword(event.target.value);
        valid = event.target.value.length !== 0;
        if (!valid) {
          setpasswordHelper("please enter password");
        } else {
          setpasswordHelper("");
        }
        break;
      default:
        break;
    }
  };

  const onScComform = () => {
    var formValid = userName.length !== 0 && password.length !== 0;
    if(userName.length === 0){
      setuserNameHelper("please enter user name");
    }
    if(password.length === 0){
      setpasswordHelper("please enter password");
    }
    var result = userLogins.filter(x=>x.userName === userName && x.password === password);
    if(result.length !== 0 && formValid)
    {
      console.log(result);
      console.log(formValid);
      setuserName("");
      setpasswordHelper("");
      setuserNameHelper("");
      setpassword("");
      dispatch(login());
      history.push('/Introduction')
    }
    else{
      setAlert({
        open: true,
        message: "oops!! UserName or Password is not correct",
        severity: "error",
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <div className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={userNameHelper.length !== 0}
            helperText={userNameHelper}
            value={userName}
            onChange={onChangeSCForm}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={passwordHelper.length !== 0}
            helperText={passwordHelper}
            value={password}
            onChange={onChangeSCForm}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onScComform}
          >
            Sign In
          </Button>
        </div>
      </div>
      <Snackbar
        open={alert.open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={10000}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

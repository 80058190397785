import React from "react";
import { Container, Typography, Box, Button, Divider } from "@material-ui/core";
import headerback from "../assets/image/headerbackground.png";
import { Link } from "react-router-dom";
import GetAppIcon from '@material-ui/icons/GetApp';

const Resources = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: "url(" + headerback + ")",
          backgroundSize: "cover",
          //objectFit:"contain",
          height: "150px",
        }}
      >
        <Container>
          <Typography
            variant="h4"
            style={{ paddingTop: "50px", marginBottom: "10px", color: "white" }}
          >
            Resources
          </Typography>
        </Container>
      </div>
      <Container>
        <Box style={{marginTop:"30px", marginBottom:"20px"}}>
          <Typography variant="h4">Postman Collection</Typography>
          <Button
            component={Link}
            to="/files/gfahub_devlabs_postman_collection.json"
            target="_blank"
            variant="contained"
            color="primary"
            endIcon={<GetAppIcon />}
            download
          >
            Download
          </Button>
          <Divider style={{marginTop:"20px"}} />
          <Typography variant="h4" style={{marginTop:"20px"}}>Airline Logos</Typography>
          <Button
            component={Link}
            to="/files/gfahub_devlabs_airlinelogos.zip"
            target="_blank"
            variant="contained"
            color="primary"
            endIcon={<GetAppIcon />}
            download
          >
            Download
          </Button>
          <Divider style={{marginTop:"20px"}} />
          <Typography variant="h4" style={{marginTop:"20px"}}>Airline List</Typography>
          <Button
            component={Link}
            to="/files/gfahub_devlabs_airlinelist.csv"
            target="_blank"
            variant="contained"
            color="primary"
            endIcon={<GetAppIcon />}
            download
          >
            Download
          </Button>
          <Divider style={{marginTop:"20px"}} />
          <Typography variant="h4" style={{marginTop:"20px"}}>Airport List</Typography>
          <Button
            component={Link}
            to="/files/gfahub_devlabs_airportslist.csv"
            target="_blank"
            variant="contained"
            color="primary"
            endIcon={<GetAppIcon />}
            download
          >
            Download
          </Button>
          <Divider style={{marginTop:"20px"}} />
          <Typography variant="h4" style={{marginTop:"20px"}}>Country List</Typography>
          <Button
            component={Link}
            to="/files/gfahub_devlabs_countrylist.csv"
            target="_blank"
            variant="contained"
            color="primary"
            endIcon={<GetAppIcon />}
            download
          >
            Download
          </Button>
		  <Divider style={{marginTop:"20px"}} />
          <Typography variant="h4" style={{marginTop:"20px"}}>API Documentation</Typography>
          <Button
            component={Link}
            to="/files/API_Documentation.pdf"
            target="_blank"
            variant="contained"
            color="primary"
            endIcon={<GetAppIcon />}
            download
          >
            Download
          </Button>
		  <Divider style={{marginTop:"20px"}} />
          <Typography variant="h4" style={{marginTop:"20px"}}>API Errors</Typography>
          <Button
            component={Link}
            to="/files/API_errors.pdf"
            target="_blank"
            variant="contained"
            color="primary"
            endIcon={<GetAppIcon />}
            download
          >
            Download
          </Button>
		  <Divider style={{marginTop:"20px"}} />
          <Typography variant="h4" style={{marginTop:"20px"}}>Integration Process Overview</Typography>
          <Button
            component={Link}
            to="/files/IntegrationProcessOverview.pdf"
            target="_blank"
            variant="contained"
            color="primary"
            endIcon={<GetAppIcon />}
            download
          >
            Download
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default Resources;

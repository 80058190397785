import React from "react";
import { Container, Typography } from "@material-ui/core";
import { RedocStandalone } from "redoc";
import PostTicketingDoc from "../assets/apidocs/PostTicketing.json";
import headerback from "../assets/image/headerbackground.png";

function FlightBooking() {
  return (
    <div>
            <div
        style={{
          backgroundImage: "url(" + headerback + ")",
          backgroundSize: "cover",
          height: "150px",
        }}
      >
        <Container>
          <Typography
            variant="h4"
            style={{ paddingTop: "50px", marginBottom: "10px", color: "white" }}
          >
            Post Ticketing
          </Typography>
        </Container>
      </div>
      <RedocStandalone
        specUrl={PostTicketingDoc}
        options={{
          nativeScrollbars: true,
          expandDefaultServerVariables: true,
          expandResponses: true,
          jsonSampleExpandLevel: "all",
          theme: { colors: { primary: { main: "#9f155a" } } },
        }}
      />
    </div>
  );
}

export default FlightBooking;

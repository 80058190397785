import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import headerback from "../assets/image/headerbackground.png";
import { makeStyles } from "@material-ui/core/styles";
import developerPortal from "../assets/image/DeveloperPortal.png";

const useStyles = makeStyles((theme) => ({
  items: {
    marginTop: "40px",
    marginBottom: "40px",
    fontSize: "1.4em",
  },
  heading: {
    paddingTop: "50px",
    marginBottom: "10px",
    color: "white",
  },
}));

const Introduction = () => {
  const classes = useStyles();

  return (
    <div>
      <div
        style={{
          backgroundImage: "url(" + headerback + ")",
          backgroundSize: "cover",
          //objectFit:"contain",
          height: "150px",
        }}
      >
        <Container>
          <Typography variant="h4" className={classes.heading}>
            Introduction
          </Typography>
        </Container>
      </div>
      <Container>
        <Grid container>
          <Grid item xs={6}>
            <img
              alt="company logo"
              style={{ width: "500px" }}
              src={developerPortal}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ marginTop: "100px", fontSize: "1.2em", lineHeight:"2em" }}>
              Get Flight and Ancillary Hub (GFA Hub) Next-generation airline
              retailing, and early-adopters are well on their way to reaping the
              benefits. By integrating with GFA Hub, we are delivering
              specialized or integrated travel services from a single sales
              platform and access travel deals from different travel
              consolidators GDS, NDC, Low Cost Carriers.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Introduction;

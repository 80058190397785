import { createMuiTheme } from "@material-ui/core/styles";

const gfsPrimary = "#9f155a";
const gfsSecondary = "#808080";

export default createMuiTheme({
  palette: {
    common: {
      primary: gfsPrimary,
      secondary: gfsSecondary,
    },
    primary: {
      main: gfsPrimary,
    },
    secondary: {
      main: gfsSecondary,
    },
  },
  typography: {
    color: "black",
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    tab: {
      textTransform: "none",
      fontWeight: 700,
      fontSize: "1em",
    },
  },
  button: {
    // `MuiButton` is the global class name for the <Button /> component
    disableElevation: true, // this prop disables the drop shadow on all Buttons
    textTransform: "none",
  },
  overrides: {
    MuiTab: {
      wrapper: {
        flexDirection: "row",
      },
    },
  },
});

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { ThemeProvider } from "@material-ui/core/styles";

//project components
import theme from "./Theme";
import Header from "../src/Components/Header";
import Footer from "../src/Components/Footer";
import Divider from "@material-ui/core/Divider";

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// pick a date util library
import DateFnsUtils from '@date-io/date-fns';
import FlightSearch from "./Pages/FlightSearch";
import FlightRevalidation from "./Pages/FlightRevalidation";
import FlightBooking from "./Pages/FlightBooking";
import PostTicketing from "./Pages/PostTicketing";
import Resources from "./Pages/Resources";
import Checklist from "./Pages/Checklist";
import Certification from "./Pages/Certification";
import FAQ from "./Pages/FAQ";
import SignIn from "./Pages/SignIn";
import Introduction from "./Pages/Introduction";
import Workflow from "./Pages/Workflow";
import ProtectedRoute from "./Components/ProtectedRoute";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <Header />
          <Divider />
          <Switch>
              <Route exact path="/" component={SignIn} />
              <ProtectedRoute exact path="/Introduction" component={Introduction} />
              <ProtectedRoute exact path="/FlightSearch" component={FlightSearch} />
              <ProtectedRoute exact path="/FlightRevalidation" component={FlightRevalidation} />
              <ProtectedRoute exact path="/FlightBooking" component={FlightBooking} />
              <ProtectedRoute exact path="/PostTicketing" component={PostTicketing} />
              <ProtectedRoute exact path="/Checklist" component={Checklist} />
              <ProtectedRoute exact path="/Certification" component={Certification} />
              <ProtectedRoute exact path="/WorkFlow" component={Workflow} />
              <ProtectedRoute exact path="/Resources" component={Resources} />
              <ProtectedRoute exact path="/FAQ" component={FAQ} />
          </Switch>
          <Footer />
        </Router>
        </MuiPickersUtilsProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;

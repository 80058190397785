import React from "react";
import { Container, Typography } from "@material-ui/core";
import { RedocStandalone } from "redoc";
import FlightSearchDoc from "../assets/apidocs/FlightSearch.json";
import headerback from "../assets/image/headerbackground.png";

function FlightSearch() {
  return (
    <div>
      <div
        style={{
          backgroundImage: "url(" + headerback + ")",
          backgroundSize: "cover",
          //objectFit:"contain",
          height: "150px",
        }}
      >
        <Container>
          <Typography
            variant="h4"
            style={{ paddingTop: "50px", marginBottom: "10px", color: "white" }}
          >
            Flight LowFareSearch
          </Typography>
        </Container>
      </div>
      <RedocStandalone
        specUrl={FlightSearchDoc}
        options={{
          nativeScrollbars: true,
          expandDefaultServerVariables: true,
          expandResponses: true,
          jsonSampleExpandLevel: "all",
          theme: {
            colors: { primary: { main: "#9f155a" } },
            typography: {
              fontFamily: "'Poppins', sans-serif",
              code: { fontFamily: "'Poppins', sans-serif" },
              headings: { fontFamily: "'Poppins', sans-serif" },
            },
          },
        }}
      />
    </div>
  );
}

export default FlightSearch;

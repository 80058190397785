import React from "react";
import { Container, Typography, Box, Divider } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import headerback from "../assets/image/headerbackground.png";
import workflowImg from "../assets/image/Workflow.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    marginTop: theme.spacing(2),
  },
}));

const Workflow = () => {
  const classes = useStyles();
  return (
    <div>
      <div
        style={{
          backgroundImage: "url(" + headerback + ")",
          backgroundSize: "cover",
          //objectFit:"contain",
          height: "150px",
        }}
      >
        <Container>
          <Typography
            variant="h4"
            style={{ paddingTop: "50px", marginBottom: "10px", color: "white" }}
          >
            Workflow
          </Typography>
        </Container>
      </div>
      <Container>
        <Box style={{ marginTop: "50px", marginBottom: "20px" }}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              marginBottom: "30px",
              color: "#6a6767",
            }}
          >
            Search to Book Workflow
          </Typography>
          <img
            alt="company logo"
            style={{ marginTop: "20px", width: "100%" }}
            src={workflowImg}
          />
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              marginBottom: "30px",
              marginTop: "30px",
              color: "#6a6767",
            }}
          >
            Search for flights:
          </Typography>
          <p>API: Sends search data entered by user to Flights/FlightSearch</p>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              marginBottom: "30px",
              marginTop: "30px",
              color: "#6a6767",
            }}
          >
            Recommendations:
          </Typography>
          <div className={classes.root}>
            <Alert severity="info" variant="outlined">
              <strong>Use filters for search:</strong>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                If the filtering is done at time of request then a more useful
                set of itineraries are returned. Consider use of filter avaiable
                in Flights/FlightSearch to eliminate non-converting airlines.
              </p>
              <strong>Use a Trace ID:</strong>
              <p style={{ marginTop: "10px" }}>
                A Trace ID is highly recommended in all requests. A unique
                TraceID can be used to track all transactions within a workflow.
                By using the TraceID,GFA-HUB customers and GFA-HUB teams are
                able to log, identify, and extract transactions for
                investigation purposes, which greatly improves speed of response
                to any support tickets.
              </p>
            </Alert>
            <Alert severity="info">
              Note : Not having the TraceID implemented causes unnecessary
              delay, and potentially no resolution, for a reported problem.
            </Alert>
          </div>
          <Divider style={{ marginTop: "20px", marginBottom: "50px" }} />
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              marginBottom: "30px",
              marginTop: "30px",
              color: "#6a6767",
            }}
          >
            Flight Revalidation:
          </Typography>
          <p>
            Selects a flight solution to validate the fare and availability.
          </p>
          <p>API: Flights/FlightRevalidation</p>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              marginBottom: "30px",
              marginTop: "30px",
              color: "#6a6767",
            }}
          >
            Recommendations:
          </Typography>
          <div className={classes.root}>
            <Alert variant="outlined" severity="info">
              <ul style={{ listStylePosition: "inside", marginLeft: "10px" }}>
                <li style={{ marginBottom: "20px" }}>
                  Ensure seat availability and fare of the selected flight
                  option.
                </li>
                <li style={{ marginBottom: "20px" }}>
                  Flight availability should always be checked before creating a
                  reservation.
                </li>
                <li style={{ marginBottom: "20px" }}>
                  Inventory levels at airlines are constantly changing, so a
                  seat available at one moment might not be available at
                  another.
                </li>
                <li style={{ marginBottom: "20px" }}>
                  If you wait too long between Flight Search and Flight booking,
                  the seat may become unavailable.
                </li>
              </ul>
            </Alert>
          </div>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              marginBottom: "30px",
              marginTop: "30px",
              color: "#6a6767",
            }}
          >
            Error:
          </Typography>
          <p>Selected Flight Option is no longer available.</p>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              marginBottom: "30px",
              marginTop: "30px",
              color: "#6a6767",
            }}
          >
            Recommendations:
          </Typography>
          <div className={classes.root}>
            <Alert variant="outlined" severity="info">
              <p>
                When the requested flight option is unavailable, notify the
                customer with an error code and not a "try again" message.
              </p>
            </Alert>
          </div>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              marginBottom: "30px",
              marginTop: "30px",
              color: "#6a6767",
            }}
          >
            FlightBooking
          </Typography>
          <p>Enter Purchase Id and with TraceI with passenger details.</p>
          <p>API: Fligts/FlightBooking</p>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              marginBottom: "30px",
              marginTop: "30px",
              color: "#6a6767",
            }}
          >
            Recommendations
          </Typography>
          <div className={classes.root}>
            <Alert variant="outlined" severity="info">
              <p>
                It is advisable to timeout the user interface by 15 minutes or
                less because the selected flight option might become invalid
                after 15 minutes. Note: This fare may no longer be available
                and/or the seat may not be available due to a change in the
                fare.
              </p>
            </Alert>
          </div>
        </Box>
      </Container>
    </div>
  );
};

export default Workflow;
